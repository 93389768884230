import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface MonthlyDestination {
  image: string;
  monthId: string;
  title: string;
  subTitle: string;
}

const slugify = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");

const MonthGallery: React.FC = () => {
  const [monthlyDestinations, setMonthlyDestinations] = useState<
    MonthlyDestination[]
  >([]);
  const [fetchingMonth, setFetchingMonth] = useState(false);

  const fetchMonthlyDestinations = async () => {
    try {
      const response: ApiResponse<{
        monthlyDestinations: MonthlyDestination[];
      }> | null = await fetchData<{
        monthlyDestinations: MonthlyDestination[];
      }>({
        url: "pages/get",
        data: { pageId: 16 },
      });

      if (response?.data?.monthlyDestinations?.length) {
        setMonthlyDestinations(response.data.monthlyDestinations);
      }
    } catch (error) {
      console.warn("Failed to load destinations. Please try again later.");
    }
  };

  useEffect(() => {
    fetchMonthlyDestinations();
  }, []);

  const handleMonthClick = (month: MonthlyDestination) => {
    if (!fetchingMonth) {
      const slug = slugify(month.title);
      window.location.href = `/monthly/${slug}/`;
    }
  };

  if (monthlyDestinations.length === 0) return null;

  return (
    <div className="container mx-auto px-2 md:px-24 mt-10">
      <h2 className="mt-0 tracking-wide sm:mt-5 text-xl lg:text-3xl font-bold font-akshar mb-2 sm:mb-4 uppercase text-center">
        Month
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 sm:gap-6">
        {monthlyDestinations.map((month) => (
          <div
            key={month.monthId}
            onClick={() => handleMonthClick(month)}
            className="cursor-pointer transition-transform duration-300 hover:scale-105 relative h-44 sm:h-64 rounded-lg overflow-hidden bg-cover bg-center shadow-md"
            style={{
              backgroundImage: `url(${month.image})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center">
              <h5 className="text-white font-mplus2 text-sm sm:text-lg font-semibold uppercase">
                {month.title}
              </h5>
              <h5 className="text-white font-mplus2 text-xs sm:text-xs font-normal">
                {month.subTitle}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthGallery;
