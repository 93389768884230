import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import Carousels from "../components/Carousels";
import CardGrid from "../components/CardGrid";
import Packages from "../components/Packages";
import About from "../components/About";
import TravelAdvisors from "../components/TravelAdvisors";
import TravelDirector from "../components/TravelDirector";
import SeamlessTravel from "../components/SeamlessTravel";
import { fetchData } from "../services/api";
import BlogSection from "../components/BlogSection";
import Testimonials from "../components/Testimonials";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle:
              "Denver-Premier Denver Travel Agency | Customized Vacations & Unforgettable Experiences",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.pageTitle}</title>
        <meta name="title" content={metaData.pageTitle} />
        <meta
          name="keywords"
          content={metaData.metaKeyword || "Default Keyword"}
        />
        <meta
          name="description"
          content={metaData.metaDescription || "Default Description"}
        />
        <link rel="canonical" href="https://travelnrelax.com/" />
      </Helmet>

      <Navbar
        heading="20 YEARS OF MAKING TRAVEL DREAMS COME TRUE"
        subHeading="UNIFYING THE WORLD, ONE TRAVEL AT A TIME"
      />

      <div className="relative z-10">
        <Aboutus />
      </div>
      <div className="relative z-10">
        <Carousels />
      </div>

      <div className="relative z-10 bg-[#F4F4F4]">
        <CardGrid />
      </div>
      <div className="relative z-10">
        <Packages />
      </div>
      <div className="z-10 w-100">
        <About />
      </div>
      <div className="z-10">
        <TravelAdvisors />
      </div>
      <div className="relative z-10">
        <TravelDirector />
      </div>
      <div className="relative z-10 mt-20">
        <SeamlessTravel />
      </div>
      <div className="relative z-10">
        <BlogSection />
      </div>
      <div className="relative z-10">
        <Testimonials />
      </div>
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <div className="relative z-10">
        <Clients />
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
