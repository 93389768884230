import React from "react";
import Navbar from "../components/Navbar";
import AnniversaryTheme from "../components/AnniversaryTheme";
import BannerFamily from "../components/BannerFamily";
import { useState, useEffect } from "react";
import HoneyMoonPreperation from "../components/HoneyMoonPreperation";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import BannerAnniversary from "../components/BannerAnniversary";
import AnniversaryPreperation from "../components/AnniversaryPreperation";
import { Helmet } from "react-helmet";
import { fetchData } from "../services/api";

const Anniversary: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 6 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 6 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle:
              "Denver-Anniversary Vacations in Aurora | Romantic Getaways & Expert Anniversary Travel Planning | Travel N Relax",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);
  return (
    <div>
      <Helmet>
        <meta name="tittle" content={metaData.pageTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/anniversary/" />
      </Helmet>
      <Navbar heading="Denver’s Expert Anniversary Travel Agency" />
      <AnniversaryTheme />
      <BannerAnniversary />
      <AnniversaryPreperation />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}

      <Clients />
      <Footer />
    </div>
  );
};

export default Anniversary;
